import { forwardRef } from 'react'
import { InputLeftElement as ChakraInputLeftElement } from '@chakra-ui/react'

// https://chakra-ui.com/docs/form/input

export const InputLeftElement = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraInputLeftElement ref={ref} {...props}>
      {props.children}
    </ChakraInputLeftElement>
  )
})
