import { css, jsx } from '@emotion/react'
import { SlideFade, useColorMode } from '@chakra-ui/react'
import { useState } from 'react'
import Div from '../Div'
import theme from '../../constants/theme'
import { Heading } from '../chakra/Heading'
import VerticalSpace from '../VerticalSpace'
import { Button } from '../chakra/Button'
import { Google } from '../chakra/icons/Google'
import HorizontalSpace from '../HorizontalSpace'
import { Input } from '../chakra/Input'
import { Checkbox } from '../chakra/Checkbox'
import { Text } from '../chakra/Text'
import useResponsive from '../../utils/hooks/useResponsive'
import isEmail from '../../utils/isEmail'
import { Toast } from '../chakra/Toast'
import userStorage from '../../utils/userStorage'
import Emoji from '../Emoji'
import { Link } from '../chakra/Link'
import { analyticsTrack } from '../../utils/analytics'
import { Box } from '../chakra/Box'
import { Twitter } from '../chakra/icons/Twitter'
import { Spotify } from '../chakra/icons/Spotify'
import { Facebook } from '../chakra/icons/Facebook'
import { Instagram} from '../chakra/icons/Instagram'
import SmallLogo from '../SmallLogo'
import Logo from '../Logo'

const styles = {
  container: disabled => css`
    width: 360px;
    margin-top: -20px;
    ${disabled ? 'pointer-events: none' : ''};

    @media screen and (max-width: 385px) {
      width: 290px;
    }
  `,
  titleFontSizes: ['24px', '28px'],
  titleDescription: css`
    text-align: center;
  `,
  privacyText: isDark => css`
    font-size: 14px;
    margin-top: 20px;
    display: inline-block;
    a {
      color: ${theme.colors.primary[isDark ? 400 : 500]};
    }
  `,
  button: css`
    width: 100%;
    font-size: 15px;
  `,
  orWrapper: css`
    width: 100%;
    position: relative;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6.5px;
    margin-bottom: 4px;
  `,
  separator: css`
    width: 100%;
    height: 1px;
    background: #9f9f9f47;
  `,
  or: colorMode => css`
    color: ${theme.colors.custom.gray};
    padding: 0 7px;
    position: absolute;
    top: 45.5%;
    transform: translateY(-50%);
    font-weight: 500;
    background: ${colorMode === 'dark' ? theme.colors.background.richBlack : '#fff'};
    transition: background ${theme.transition.duration.background};
    font-size: 14px;
  `,
  checkboxWrapper: isDark => css`
    width: 100%;
    [class^='chakra-checkbox__control']:not([data-checked]):not([data-disabled]),
    [class*=' chakra-checkbox__control']:not([data-checked]):not([data-disabled]) {
      background: ${isDark ? 'rgba(255,255,255,0.05)' : '#edf2f7'};
      border-color: transparent;
    }
    [data-disabled] {
      opacity: ${isDark ? '0.4' : '1'};
    }
  `,
  textFontSize: ['14px', '15px'],
}
//coment for commit
function LoginForm({ onGoogleSign, onEmailSign, onTwitterSign, onSpotifySign, onFacebookSign, onInstagramSign}) {
  const [googleLoading, setGoogleLoading] = useState(false)
  const [twitterLoading, setTwitterLoading] = useState(false)
  const [spotifyLoading, setSpotifyLoading] = useState(false)
  const [facebookLoading, setFacebookLoading] = useState(false)
  const [instagramLoading, setInstagramLoading] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const buttonSize = useResponsive('sm', 'md')
  const iconSize = useResponsive(5, 6)
  const checkBoxSize = useResponsive('md', 'lg')
  const logoSize = useResponsive(20, 20.5)
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const [emailValue, setEmailValue] = useState(() => {
    const rememberLoginUserStorage = userStorage.getDataByKey('rememberLogin')
    const { remember } = rememberLoginUserStorage
    return remember ? rememberLoginUserStorage.email : ''
  })
  const [rememberLogin, setRememberLogin] = useState(() => userStorage.getDataByKey('rememberLogin')?.remember)
  const disableContainer = emailLoading || googleLoading || spotifyLoading || twitterLoading

  return (
    <SlideFade offsetX={0} offsetY={50} in>
      <Div flex column css={styles.container(disableContainer)}>
        <Div center>
        <Logo size={logoSize} />
        </Div>
        <VerticalSpace space={3} />
        <Div center column>
          <Heading as="h1" fontWeight="extrabold" block fontSize={styles.titleFontSizes}>
            Welcome to BackToIt
          </Heading>
          <VerticalSpace />
          <Box block fontWeight="normal" css={styles.titleDescription} fontSize={styles.textFontSize}>
            Choose between Email, Google, Twitter or Facebook!
          </Box>
          <VerticalSpace space={3} />
          <Button
            colorScheme="red"
            lightMode
            css={styles.button}
            fontWeight="semibold"
            size={buttonSize}
            isLoading={googleLoading}
            onClick={() => {
              analyticsTrack('login_google')
              setGoogleLoading(true)
              onGoogleSign()
            }}
          >
            <Div center>
              <Google boxSize={iconSize} />
              <HorizontalSpace />
              Continue with Google
            </Div>
          </Button>
          <VerticalSpace space={1.8} />
          <Button
            colorScheme="twitter"
            lightMode
            css={styles.button}
            fontWeight="semibold"
            size={buttonSize}
            isLoading={twitterLoading}
            onClick={() => {
              analyticsTrack('login_twitter')
              setTwitterLoading(true)
              onTwitterSign()
            }}
          >
            <Div center>
              <Twitter boxSize={iconSize} />
              <HorizontalSpace />
              Continue with Twitter
            </Div>
          </Button>
          {/*<VerticalSpace space={1.8} />
          <Button
            colorScheme="green"
            lightMode
            css={styles.button}
            fontWeight="semibold"
            size={buttonSize}
            isLoading={spotifyLoading}
            onClick={() => {
              analyticsTrack('login_spotify')
              setSpotifyLoading(true)
              onSpotifySign()
            }}
          >
            <Div center>
              <Spotify boxSize={iconSize} />
              <HorizontalSpace />
              Continue with Spotify
            </Div>
          </Button>*/}
          <VerticalSpace space={1.8} />
          <Button
            colorScheme="facebook"
            lightMode
            css={styles.button}
            fontWeight="semibold"
            size={buttonSize}
            isLoading={facebookLoading}
            onClick={() => {
              analyticsTrack('login_facebook')
              setFacebookLoading(true)
              onFacebookSign()
            }}
          >
            <Div center>
              <Facebook boxSize={iconSize} />
              <HorizontalSpace />
              Continue with Facebook
            </Div>
          </Button>
          {/*<VerticalSpace space={1.8} />
          <Button
            colorScheme="pink"
            lightMode
            css={styles.button}
            fontWeight="semibold"
            size={buttonSize}
            isLoading={instagramLoading}
            onClick={() => {
              analyticsTrack('login_instagram')
              setInstagramLoading(true)
              onInstagramSign()
            }}
          >
            <Div center>
              <Instagram boxSize={iconSize} />
              <HorizontalSpace />
              Continue with Instagram
            </Div>
          </Button>*/}
          <VerticalSpace space={0.5} />
          <div css={styles.orWrapper}>
            <div css={styles.separator} />
            <span css={styles.or(colorMode)}>or receive a login link</span>
          </div>
          <VerticalSpace space={0.5} />
          <Input
            variant="filled"
            colorScheme="accentedButton"
            placeholder="Email"
            size={buttonSize}
            value={emailValue}
            onChange={e => {
              const val = e.target.value
              setEmailValue(val)
              if (emailInvalid) {
                setEmailInvalid(false)
                Toast.closeAll()
              }
            }}
            type="email"
            isInvalid={emailInvalid}
          />
          <Div css={styles.checkboxWrapper(isDark)}>
            <VerticalSpace space={1.5} />
            <Checkbox
              size={checkBoxSize}
              colorScheme="accentedButton"
              lightMode
              isChecked={rememberLogin}
              isDisabled={!isEmail(emailValue)}
              onChange={e => {
                const { checked } = e.target
                setRememberLogin(checked)
                userStorage.setData({
                  rememberLogin: {
                    remember: checked,
                    email: emailValue,
                  },
                })
              }}
            >
              <Text fontSize={styles.textFontSize}>Remember me</Text>
            </Checkbox>
          </Div>
          <div>
            <Text css={styles.privacyText}>
              By using this product you agree to the{' '}
              <Link href="/terms-of-service" colorScheme="accentedButton">
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link href="/privacy-policy" colorScheme="accentedButton">
                Privacy Policy
              </Link>
            </Text>
          </div>
          <VerticalSpace space={4} />
          <Button
            colorScheme="accentedButton"
            lightMode
            css={styles.button}
            fontWeight="semibold"
            size={buttonSize}
            isLoading={emailLoading}
            onClick={() => {
              if (!isEmail(emailValue)) {
                setEmailInvalid(true)
                Toast({
                  title: 'Email is invalid',
                  description: 'Please provide a valid email',
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                  position: 'top-right',
                })
                return
              }
              analyticsTrack('login_email')
              setEmailLoading(true)
              onEmailSign(emailValue)
              userStorage.setData({
                rememberLogin: {
                  remember: rememberLogin,
                  email: emailValue,
                },
              })
              Toast({
                title: 'Sending Email',
                description: 'Please wait...',
                icon: <Emoji>📩</Emoji>,
                duration: null,
                isClosable: true,
                position: 'top-right',
              })
            }}
          >
            Sign in
          </Button>
        </Div>
      </Div>
    </SlideFade>
  )
}

export default LoginForm
