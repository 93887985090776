import { css, jsx } from '@emotion/react'
import Div from '../Div'
import { ChevronDown } from '../chakra/icons/ChevronDown'
import { Button } from '../chakra/Button'
import { Text } from '../chakra/Text'

const styles = {
  container: css`
    position: absolute;
    left: 0;
  `,
  top: ['20px', '30px'],
  backIcon: css`
    transform: rotate(90deg);
  `,
  text: css`
    margin-right: 10px;
    position: relative;
    bottom: 1px;
  `,
  fontSize: ['15px', '16px'],
  iconSize: [5, 6],
}

function GoBack() {
  return (
    <Div css={styles.container} top={styles.top}>
      <Button to="/" variant="link" color="">
        <span>
          <Div center>
            <ChevronDown boxSize={styles.iconSize} css={styles.backIcon} />
            <Text fontWeight="semibold" css={styles.text} fontSize={styles.fontSize}>
              Back to Home
            </Text>
          </Div>
        </span>
      </Button>
    </Div>
  )
}

export default GoBack
