import { useCallback, useEffect } from 'react'
import { css, jsx } from '@emotion/react'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import Div from '../components/Div'
import GoBack from '../components/login/GoBack'
import LoginForm from '../components/login/LoginForm'
import MaxWidth from '../components/MaxWidth'
import AutoFetchPage from '../components/AutofetchPage'

const styles = {
  content: css`
    height: 91%;
  `,
  container: css`
    height: 100%;
    position: relative;
  `,
}

function Login() {
  const router = useRouter()
  const { error } = router?.query



  useEffect(() => {
    if (error) {
      router.push(`/login-error?error=${error}`)
    }
  }, [error])

  const googleSigning = useCallback(() => {
    const callbackUrl = router.query.callbackUrl || `${document.location.origin}/dashboard`
    signIn('google', {
      callbackUrl,
    })
  }, [router])

  const emailSigning = useCallback(
    email => {
      const callbackUrl = router.query.callbackUrl || `${document.location.origin}/dashboard`
      signIn('email', {
        email,
        callbackUrl,
      })
    },
    [router]
  )

  const twitterSigning = useCallback(() => {
    const callbackUrl = router.query.callbackUrl || `${document.location.origin}/dashboard`
    signIn('twitter', {
      callbackUrl,
    })
  }, [router])

  const spotifySigning = useCallback(() => {
    const callbackUrl = router.query.callbackUrl || `${document.location.origin}/dashboard`
    signIn('spotify', {
      callbackUrl,
    })
  }, [router])

  const faceBookSigning = useCallback(async () => {
    const callbackUrl = router.query.callbackUrl || `${document.location.origin}/dashboard`
    await signIn('facebook', {
      callbackUrl,
    })
  }, [router])

  const instagramSigning = useCallback(() => {
    const callbackUrl = router.query.callbackUrl || `${document.location.origin}/dashboard`
    signIn('instagram', {
      redirectUri: callbackUrl,
    })
  }, [router])

  return (
    <MaxWidth maxWidth={1200} css={styles.content}>
      <Div css={styles.container} center>
        <GoBack />
        <LoginForm
          onGoogleSign={googleSigning}
          onEmailSign={emailSigning}
          onTwitterSign={twitterSigning}
          onSpotifySign={spotifySigning}
          onFacebookSign={faceBookSigning}
          onInstagramSign={instagramSigning}
        />
      </Div>
      <AutoFetchPage url="/email-sent" />
      <AutoFetchPage url="/login-error" />
      <AutoFetchPage url="/dashboard" />
    </MaxWidth>
  )
}

export default Login
