import { memo } from 'react'
import { Link } from './chakra/Link'

function AutoFetchPage({ url }) {
  return (
    <Link
      href={url}
      style={{ opacity: 0, visibility: 'hidden', position: 'fixed', top: '-200vh', height: 0, width: 0 }}
      className="AutoFetchPage"
    />
  )
}

export default memo(AutoFetchPage)
