import { forwardRef } from 'react'
import { Checkbox as ChakraCheckbox, LightMode } from '@chakra-ui/react'
import { css, jsx } from '@emotion/react'

// https://chakra-ui.com/docs/overlay/menu

const styles = css`
  div[class^='chakra-checkbox__control'],
  div[class*=' chakra-checkbox__control'] {
    border-radius: 0.23rem;
  }
`

export const Checkbox = forwardRef(({ lightMode, css: propsCss = '', className = '', ...props }, ref) => {
  return lightMode ? (
    <LightMode>
      <ChakraCheckbox ref={ref} {...props} className={`${propsCss} ${className}`} css={styles}>
        {props.children}
      </ChakraCheckbox>
    </LightMode>
  ) : (
    <ChakraCheckbox ref={ref} {...props} className={`${propsCss} ${className}`} css={styles}>
      {props.children}
    </ChakraCheckbox>
  )
})
