import { forwardRef } from 'react'
import { Input as ChakraInput } from '@chakra-ui/react'
import { css, jsx } from '@emotion/react'
import theme from '../../../constants/theme'

// https://chakra-ui.com/docs/form/input

const styles = css`
  border-radius: 0.375rem;
  &:focus {
    border-color: ${theme.colors.primary.base};
    box-shadow: 0 0 0 1px ${theme.colors.primary.base};
  }
  ::placeholder {
    font-weight: 500;
    color: ${theme.colors.custom.gray};
  }
`

export const Input = forwardRef(({ css: propsCss = '', className = '', ...props }, ref) => {
  return (
    <ChakraInput autoComplete="off" ref={ref} {...props} className={`${propsCss} ${className}`} css={styles}>
      {props.children}
    </ChakraInput>
  )
})
