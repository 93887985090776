import { forwardRef } from 'react'
import { InputGroup as ChakraInputGroup } from '@chakra-ui/react'

// https://chakra-ui.com/docs/form/input

export const InputGroup = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraInputGroup ref={ref} {...props}>
      {props.children}
    </ChakraInputGroup>
  )
})
